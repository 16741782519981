import React, { useState, useEffect } from 'react';
import { 
  Button, 
  TextField, 
  Typography, 
  Alert, 
  CircularProgress,
  Box,
  InputAdornment,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Snackbar
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import { createInvoice, checkPaymentStatus } from '../utils/Api';
import { useNavigate, useLocation } from 'react-router-dom';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { useAuth } from '../App';

const Renewal = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { handleAuthenticated } = useAuth();

  const [step, setStep] = useState(1);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState([]);
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [deviceId, setDeviceId] = useState('');
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // User data passed from login
  const { username, email, referralCode } = location.state || {};

  useEffect(() => {
    const generateDeviceId = async () => {
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      setDeviceId(result.visitorId);
    };

    generateDeviceId();
  }, []);

  const plans = [
    { id: '3days', label: '3 Days Access', price: 150, days: 3 },
    { id: '14days', label: '14 Days Access', price: 600, days: 14 },
    { id: '30days', label: '30 Days Access', price: 1000, days: 30 },
  ];

  const handlePlanChange = (event) => {
    setSelectedPlan(event.target.value);
  };

  const handleCheckPaymentStatus = async (invoiceId) => {
    try {
      const response = await checkPaymentStatus(invoiceId);
      
      if (response.status === 'complete') {
        setConfirmationMessage('Subscription renewed successfully');
        setStep(3);
        handleAuthenticated(response.token, 'Subscription renewed successfully');
        setTimeout(() => navigate('/wallet-checker'), 3000);
      } else if (response.status === 'expired') {
        setError(['Payment expired. Please try again.']);
        setStep(1);
      } else {
        setError(['Payment not completed. Please try again.']);
        setStep(1);
      }
    } catch (error) {
      console.error('Error checking payment status:', error);
      setError(['Error verifying payment. Please contact support.']);
      setStep(1);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePayment = async (e) => {
    e.preventDefault();
    if (!selectedPlan) {
      setError(['Please select a plan']);
      return;
    }

    setIsLoading(true);
    setError([]);

    try {
      const selectedPlanDetails = plans.find(plan => plan.id === selectedPlan);
      
      const response = await createInvoice({
        plan: selectedPlan,
        price: selectedPlanDetails.price,
        referralCode,
        buyer_email: email,
        notes: `Phone: ${phoneNumber}, Username: ${username}`,
        isRenewal: true
      });

      const { invoiceId } = response;

      window.bitcart.onModalWillEnter(() => {
        console.log('Payment modal opened');
      });

      window.bitcart.onModalWillLeave(() => {
        console.log('Payment modal closed');
        handleCheckPaymentStatus(invoiceId);
      });

      window.bitcart.showInvoice(invoiceId);
    } catch (error) {
      console.error('Error creating invoice:', error);
      if (error.response?.data?.errors) {
        setError(error.response.data.errors);
      } else {
        setError(['Failed to initiate payment. Please try again.']);
      }
      setIsLoading(false);
    }
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <>
            <Typography variant="h5" component="div" gutterBottom align="center">
              Renew Your Subscription
            </Typography>
            <form onSubmit={handlePayment}>
              <TextField
                label="Username"
                variant="outlined"
                fullWidth
                margin="normal"
                value={username}
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon sx={{ color: 'gold' }} />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Password"
                type="password"
                variant="outlined"
                fullWidth
                margin="normal"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon sx={{ color: 'gold' }} />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Phone Number"
                variant="outlined"
                fullWidth
                margin="normal"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneIcon sx={{ color: 'gold' }} />
                    </InputAdornment>
                  ),
                }}
              />
              <FormControl fullWidth margin="normal">
                <InputLabel id="subscription-plan-label">Subscription Options</InputLabel>
                <Select
                  labelId="subscription-plan-label"
                  id="subscription-plan"
                  value={selectedPlan}
                  label="Subscription Options"
                  onChange={handlePlanChange}
                >
                  {plans.map((plan) => (
                    <MenuItem key={plan.id} value={plan.id}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <PersonIcon sx={{ mr: 1, color: 'gold' }} />
                        <Typography>{`${plan.label} - $${plan.price}`}</Typography>
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                type="submit"
                variant="contained"
                fullWidth
                disabled={isLoading}
                sx={{ 
                  mt: 2, 
                  py: 1,
                  backgroundColor: '#FFD700',
                  color: '#000',
                  '&:hover': {
                    backgroundColor: '#FFC107',
                  },
                }}
              >
                {isLoading ? <CircularProgress size={24} /> : 'Renew Subscription'}
              </Button>
            </form>
          </>
        );
      case 2:
        return (
          <>
            <Typography variant="h5" component="div" gutterBottom align="center">
              Payment in Progress
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <CircularProgress />
            </Box>
            <Typography variant="body1" sx={{ mt: 2 }} align="center">
              Please complete the payment in the modal window.
            </Typography>
          </>
        );
      case 3:
        return (
          <>
            <Typography variant="h5" component="div" gutterBottom align="center">
              Renewal Complete
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }} align="center">
              {confirmationMessage}
            </Typography>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Box sx={{ width: '100%', maxWidth: 500, mx: 'auto', p: 3, bgcolor: 'background.paper', boxShadow: 3, borderRadius: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
        <img src="/cryptocraft.png" alt="WalletFinder Logo" style={{ width: '200px', height: 'auto' }} />
      </Box>
      {renderStep()}
      {error.length > 0 && step !== 2 && error.map((err, index) => (
        <Alert key={index} severity="error" sx={{ mt: 2 }}>
          {err}
        </Alert>
      ))}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default Renewal;