// theme.js
import { createTheme } from '@mui/material/styles';

export const createAppTheme = (darkMode) =>
  createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
      background: {
        default: darkMode ? '#1a1a1a' : '#f5f5f5',
        paper: darkMode ? '#2a2a2a' : '#ffffff',
      },
      text: {
        primary: darkMode ? '#ffffff' : '#000000',
        secondary: darkMode ? '#b0b0b0' : '#666666',
      },
    },
    typography: {
      fontFamily: 'Roboto',
      // Optionally, customize other typography settings
      // h1: { ... },
      // body1: { ... },
    },
  });