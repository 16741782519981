// frontend/components/WalletChecker.js

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { 
  Container, 
  Card, 
  CardContent, 
  CardActions, 
  Box, 
  Button, 
  Typography, 
  Snackbar, 
  Alert 
} from '@mui/material';
import { ThemeProvider, CssBaseline } from '@mui/material';
import TopBar from './TopBar';
import NetworkSelector from './NetworkSelector';
import PhraseDisplay from './PhraseDisplay';
import ResultsModal from './ResultsModal';
import { createAppTheme } from '../utils/Theme.js';
import { 
  fetchUserData, 
  generatePhrases, 
  startWalletCheck as apiStartWalletCheck, 
  getWalletCheckResult,
  fetchUnseenResults, 
  markResultAsSeen
} from '../utils/Api.js';
import { SUPPORTED_NETWORKS } from '../utils/Constants.js';
import testWalletCheck from '../utils/testWalletCheck.js';
import FoundMoneyModal from './FoundMoneyModal.js';

const WalletChecker = ({ token, onLogout }) => {
    // State variables
    const [selectedNetworks, setSelectedNetworks] = useState([]);
    const [checkResults, setCheckResults] = useState([]);
    const [days, setDays] = useState(365);
    const [checked, setChecked] = useState(0);
    const [balance, setBalance] = useState(0);
    const [isRunning, setIsRunning] = useState(false);
    const [user, setUser] = useState(null);
    const [openResults, setOpenResults] = useState(false);
    const [darkMode, setDarkMode] = useState(() => {
      const savedMode = localStorage.getItem('darkMode');
      return savedMode ? JSON.parse(savedMode) : true;
    });
    const [currentPhrases, setCurrentPhrases] = useState({});
    const [isGenerating, setIsGenerating] = useState(false);
    const [isChecking, setIsChecking] = useState(false);
    const [error, setError] = useState(null);
    const [currentWalletCheckId, setCurrentWalletCheckId] = useState(null);
    const [progress, setProgress] = useState({});
    const [isStopping, setIsStopping] = useState(false);
    const [numberOfChecks, setNumberOfChecks] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [currentResult, setCurrentResult] = useState(null);
    
    // Refs to keep track of isRunning and isStopping states inside async functions
    const isRunningRef = useRef(isRunning);
    const isStoppingRef = useRef(isStopping);
    const cycleTimeoutRef = useRef(null);

    var maxLoops = 1000;
    let loopCount = 0;
  
    // Update the refs whenever isRunning or isStopping changes
    useEffect(() => {
      isRunningRef.current = isRunning;
      console.log()
    }, [isRunning]);
  
    useEffect(() => {
      isStoppingRef.current = isStopping;
    }, [isStopping]);
  
    // Function to handle errors
    const handleError = (error) => {
      console.error('Error:', error);
      setError(error.message || 'An unexpected error occurred.');
      setIsRunning(false);
      setIsGenerating(false);
      setIsChecking(false);
      setIsStopping(false);
    };
  
    const checkResults2 = async () => {
      try {
        const results = await fetchUnseenResults(token);
        if (results.length > 0) {
          // Assuming you want to show the latest result
          const latestResult = results[0];
          setCurrentResult(latestResult);
          setModalOpen(true);
  
          // Mark the result as seen
          //await markResultAsSeen(token, latestResult._id);
        }
        //console.log('TADAA Checked for results:', results);
      } catch (error) {
        console.error('Error checking results:', error);
      }
    };
  
    const handleCloseModal = useCallback(async () => {
      setModalOpen(false);
      if (currentResult) {
        try {
          await markResultAsSeen(currentResult._id);
          //console.log('Marked result as seen after modal close');
          setCurrentResult(null); // Clear the current result
        } catch (error) {
          console.error('Error marking result as seen:', error);
          // Optionally, handle the error (e.g., retry, notify the user)
        }
      }
    }, [token, currentResult]);
    
    // Function to update results
    const updateResults = useCallback((results) => {
      const updatedResults = results.map(result => ({
        ...result,
        timestamp: new Date().toISOString()
      }));
      setCheckResults(prev => {
        const newResults = [...prev];
        updatedResults.forEach(result => {
          const index = newResults.findIndex(r => r.network === result.network);
          if (index !== -1) {
            newResults[index] = result;
          } else {
            newResults.push(result);
          }
        });
        return newResults;
      });
      setChecked(updatedResults.filter(r => r.checked).length);
      const newBalance = updatedResults.reduce((sum, result) => sum + parseFloat(result.balance || 0), 0);
      formatUSD(newBalance);
      setBalance(newBalance);
      //setOpenResults(true); // Open the ResultsModal when results are updated
    }, []);
  
    // Function to clear results
    const clearResults = useCallback(() => {
      setCheckResults([]);
      //setChecked(0);
      //setBalance(0);
      console.log('Results cleared');
    }, []);
  
    /**
     * Combined function to run the wallet check cycle.
     * This function generates phrases, starts the wallet check, waits for results,
     * and ensures the cycle doesn't proceed until results are back.
     */
    const resetProgress = useCallback(() => {
      setProgress({});
      //setChecked(0);
      //setBalance(0);
    }, []);

  const runWalletCheckCycle = useCallback(async () => {
    //console.log('Starting wallet check cycle');
  
    if (cycleTimeoutRef.current) {
      console.log('Wallet check cycle is already running.');
      return;
    }
  
    try {
      if (isStoppingRef.current) {
        console.log('Wallet check cycle not started or is stopping');
        return;
      }
  
      //console.log('Selected networks:', selectedNetworks);
      setIsGenerating(true);
      //console.log('Generating phrases...');
  
      resetProgress();
  
      let phrases;
      try {
        phrases = await generatePhrases(selectedNetworks);
        //console.log('Generated phrases:', JSON.stringify(phrases, null, 2));
      } catch (error) {
        console.error('Error generating phrases:', error);
        throw error;
      }
  
      setCurrentPhrases(phrases);
      setIsGenerating(false);
  
      if (phrases && Object.keys(phrases).length > 0) {
        setIsChecking(true);
        //console.log('Starting wallet check...');
  
        let walletCheckId;
        try {
          // Ensure selectedNetworks is an array
          const networksToCheck = Array.isArray(selectedNetworks) ? selectedNetworks : Object.keys(phrases);
          //console.log('Networks to check:', networksToCheck);
          
          const response = await apiStartWalletCheck(phrases, networksToCheck);
          walletCheckId = response.walletCheckId;
          //console.log('Wallet check started. ID:', walletCheckId);
          setCurrentWalletCheckId(walletCheckId);
        } catch (error) {
          console.error('Error starting wallet check:', error);
          console.error('Error details:', error.response?.data);
          throw error;
        }
  
        //console.log('Waiting for wallet check results...');
        try {
          let retries = 0;
          const maxRetries = 12;
          
          while (retries < maxRetries) {
            try {
              //console.log('Checking wallet check result...');
              const data = await getWalletCheckResult(walletCheckId);
              //console.log('Received wallet check result:', JSON.stringify(data, null, 2));
              
              if (!data) {
                console.error('Received empty data from getWalletCheckResult');
                throw new Error('Empty response from server');
              }
    
              if (data.status === 'completed') {
                if (Array.isArray(data.networks)) {
                  updateResults(data.networks);
                  setProgress(prev => ({
                    ...prev,
                    ...Object.fromEntries(data.networks.map(network => [network.network, 100]))
                  }));
                } else {
                  console.error('Completed status but networks is not an array:', data);
                  throw new Error('Invalid data structure: networks is not an array');
                }
                break; // Exit the loop when completed
              } else if (data.status === 'error') {
                throw new Error(data.error || 'Unknown error occurred');
              } else if (data.status === 'running' || data.status === 'processing') {
                if (Array.isArray(data.networks)) {
                  const checkedNetworks = data.networks.filter(n => n.checked);
                  setChecked(checkedNetworks.length);
                  setBalance(checkedNetworks.reduce((sum, n) => sum + (n.balance || 0), 0));
                  setProgress(prev => ({
                    ...prev,
                    ...Object.fromEntries(
                      data.networks.map(network => [network.network, network.checked ? 100 : 0])
                    )
                  }));
                  //console.log(`Progress: Checked ${checkedNetworks.length}, Balance: ${checkedNetworks.reduce((sum, n) => sum + (n.balance || 0), 0)}`);
                } else {
                  console.warn('Running/processing status but networks is not an array:', data);
                  // Continue the loop without updating progress
                }
              } else {
                console.warn('Unknown status received:', data.status);
              }
    
              await new Promise(resolve => setTimeout(resolve, 10000));
              retries++;
              console.log(`Retrying... (${retries}/${maxRetries})`);
            } catch (error) {
              console.error('Error fetching wallet check result:', error);
              retries++;
              if (retries >= maxRetries) {
                setError('Failed to fetch wallet check result after multiple attempts');
                break;
              }
    
              await new Promise(resolve => setTimeout(resolve, 10000));
              console.log(`Retrying after error... (${retries}/${maxRetries})`);
            }
          }

          try {
            const updatedUserData = await fetchUserData(token);
            setUser(updatedUserData);
            setDays(updatedUserData.daysLeftInSubscription);
            setNumberOfChecks(updatedUserData.numberOfChecks);
            formatUSD(updatedUserData.balance);
            setBalance(updatedUserData.balance);
          } catch (error) {
            console.error('Error fetching updated user data:', error);
          }
        } catch (error) {
          console.error('Error during wallet check cycle:', error);
          handleError(error);
          return;
        }
  
        setIsChecking(false);
      } else {
        throw new Error('Failed to generate phrases');
      }
  
    } catch (error) {
      console.error('Error during wallet check cycle:', error);
      handleError(error);
    } finally {
      if (isRunningRef.current && !isStoppingRef.current) {
        cycleTimeoutRef.current = setTimeout(runWalletCheckCycle, 100);
      } else {
        checkResults2();
        //console.log('Wallet check cycle ended');
        setIsRunning(false);
        setIsStopping(false);
        setIsGenerating(false);
        setIsChecking(false);
      }
    }
  }, [token, selectedNetworks, generatePhrases, apiStartWalletCheck, handleError, updateResults, getWalletCheckResult, resetProgress]);

    // Function to start wallet check
    const handleStartWalletCheck = useCallback(() => {
      if (selectedNetworks.length === 0) {
        setError('Please select at least one network');
        return;
      }
      maxLoops = 1000;
      console.log('HandleStartWalletCheck called');
      setIsRunning(true);
      setIsStopping(false);
      setError(null);
    }, [selectedNetworks]);
  
    // Function to stop wallet check
    const stopWalletCheck = useCallback(() => {
      //console.log('Stopping wallet check');
      setIsRunning(false);
      setIsStopping(true);
    }, []);
  
    // Effect to start the cycle when isRunning is true
    useEffect(() => {
      //console.log('isRunning changed:', isRunning);
      if (isRunning) {
        
    
      const runLoop = async () => {
        while (loopCount < maxLoops) {
          await runWalletCheckCycle();
          loopCount++;
        }
      };
    
      runLoop();
      }
      // Cleanup function to stop the loop if the component unmounts
      return () => {
        setIsRunning(false);
        setIsStopping(false);
        if (cycleTimeoutRef.current) {
          clearTimeout(cycleTimeoutRef.current);
        }
      };
    }, [isRunning, runWalletCheckCycle]);
  
    // Fetch user data on component mount
    useEffect(() => {
      //console.log('Token:', token);
      const getUserData = async () => {
        try {
          const userData = await fetchUserData(token);
          setUser(userData);
          formatUSD(userData.balance);
          setBalance(userData.balance);
          if (userData && userData.daysLeftInSubscription !== undefined) {
            setDays(userData.daysLeftInSubscription);
            setNumberOfChecks(userData.numberOfChecks);
          }
        } catch (error) {
          handleError(error);
        }
      };
  
      getUserData();
    }, [token]);
  
    // Log when selected networks change
    useEffect(() => {
      //console.log('Selected networks changed:', selectedNetworks);
    }, [selectedNetworks]);
  
    // Function to toggle dark mode
    const toggleDarkModeHandler = () => {
      setDarkMode(prevMode => {
        const newMode = !prevMode;
        localStorage.setItem('darkMode', JSON.stringify(newMode));
        return newMode;
      });
    };
  
    // Function to toggle network selection
    const toggleNetworkHandler = (network) => {
      setSelectedNetworks(prev =>
        prev.includes(network)
          ? prev.filter(n => n !== network)
          : [...prev, network]
      );
    };
  
    // Function to open results modal
    const openResultsModal = useCallback(() => {
      //console.log('Opening results modal');
      setOpenResults(true);
    }, []);
  
    // Function to close results modal
    const closeResultsModal = useCallback(() => {
      //console.log('Closing results modal');
      setOpenResults(false);
    }, []);
  
    const formatUSD = (amount) => {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(amount);
    };
    
    // Define the theme
    const theme = createAppTheme(darkMode);
  
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container maxWidth="sm">
          <TopBar 
            user={user} 
            days={days} 
            onLogout={onLogout} 
            onDarkModeToggle={toggleDarkModeHandler}
            onOpenResults={openResultsModal}
            darkMode={darkMode}
          />
          <Card sx={{ mt: 2, mb: 5 }}>
            <CardContent>
              <NetworkSelector
                networks={SUPPORTED_NETWORKS}
                selectedNetworks={selectedNetworks}
                onToggle={toggleNetworkHandler}
                phrases={currentPhrases}
            isGenerating={isGenerating}
            isChecking={isChecking}
            isRunning={isRunning}
            progress={progress}
            
              />
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <Typography variant="body2">Checked: {numberOfChecks}</Typography>
            <Typography 
              variant="body2" 
              component="div"
              sx={{ 
                mr: 1,
                //display: { xs: 'none', sm: 'block' }
              }}
            >
              Days left: {days}
            </Typography>
            <Typography variant="body2" color="success.main">${balance.toFixed(2)}</Typography>
          </Box>
              <PhraseDisplay
            phrases={currentPhrases}
            isGenerating={isGenerating}
            isChecking={isChecking}
            progress={progress}
            selectedNetworks={selectedNetworks}
          />
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button 
                  variant="contained" 
                  color="primary"
                  onClick={handleStartWalletCheck}
                  disabled={isRunning || selectedNetworks.length === 0 || isGenerating || isChecking}
                  sx={{ 
                    flexGrow: 1,
            mr: 1, 
            fontSize: '1rem',
            backgroundColor: '#FFD700', // Gold color
            color: '#000', // Black text for contrast
            '&:hover': {
              backgroundColor: '#FFC107', // Darker gold on hover
            },
          }}
                >
                  START
                </Button>
                {/* <Button 
                  variant="contained" 
                  color="secondary"
                  onClick={stopWalletCheck}
                  disabled={ !isRunning}
                  sx={{ flexGrow: 1, ml: 1 }}
                >
                  STOP
                </Button> */}
              </Box>
              {/* <Box sx={{ mt: 2 }}>
                <Button
                  variant="outlined"
                  color="info"
                  onClick={handleTestWalletCheck}
                  fullWidth
                >
                  Run Test Wallet Check
                </Button>
              </Box> */}
            </CardContent>
            <CardActions sx={{ justifyContent: 'center', flexDirection: 'column' }}>
              <Typography variant="body2" color="text.secondary">
                © 2024 CryptoCraft. All rights reserved.
              </Typography>
              <Typography variant="body2" color="text.secondary">
                We are 50% Robin Hood.
              </Typography>
            </CardActions>
          </Card>
          <ResultsModal
            open={openResults}
            onClose={closeResultsModal}
            results={checkResults}
            totalBalance={balance}
            totalChecked={checked}
            clearResults={clearResults}
            token={token}
          />
          <FoundMoneyModal isOpen={modalOpen} onClose={handleCloseModal} result={currentResult} />
          <Snackbar 
            open={!!error} 
            autoHideDuration={6000} 
            onClose={() => setError(null)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
              {error}
            </Alert>
          </Snackbar>
        </Container>
      </ThemeProvider>
    );
  };
  
  export default WalletChecker;