import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';

// Create an axios instance with default config
const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor to include the token in the header
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});


export const login = async (username, password, deviceId, rememberMe) => {
  try {
    const response = await api.post('/api/auth/login', {
      username,
      password,
      deviceId,
      rememberMe
    });
    return response.data;
  } catch (error) {
    throw handleError(error);
  }
};

const handleError = (error) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    const errorData = error.response.data;
    errorData.status = error.response.status;
    return errorData;
  } else if (error.request) {
    // The request was made but no response was received
    return { message: 'No response received from server', status: 0 };
  } else {
    // Something happened in setting up the request that triggered an Error
    return { message: error.message, status: 0 };
  }
};

export const fetchUserData = async () => {
  try {
    const response = await api.get('/api/auth/user');
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const generatePhrases = async (networks) => {
  try {
    const response = await api.post('/api/wallet-check/generate-phrase', {
      networks,
      wordCount: 12
    });
    return response.data.phrases;
  } catch (error) {
    return handleError(error);
  }
};

export const startWalletCheck = async (phrases, networks) => {
  try {
    const response = await api.post('/api/wallet-check/start', {
      phrases,
      networks
    });
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const fetchWalletCheckResults = async (options = {}) => {
  try {
    const { token, page = 1, limit = 20, showWinning = false } = options;
    const response = await api.get('/api/wallet-check/results', {
      params: { page, limit, showWinning },
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const getWalletCheckResult = async (walletCheckId) => {
  try {
    const response = await api.get(`/api/wallet-check/result/${walletCheckId}`);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const logout = async () => {
  try {
    const response = await api.post('/api/auth/logout');
    localStorage.removeItem('token');
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

// Admin functions
export const getUsers = async () => {
  try {
    const response = await api.get('/api/admin/users');
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const addUser = async (userData) => {
  try {
    const response = await api.post('/api/admin/users', userData);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const updateUser = async (userId, userData) => {
  try {
    const response = await api.put(`/api/admin/users/${userId}`, userData);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const deleteUser = async (userId) => {
  try {
    const response = await api.delete(`/api/admin/users/${userId}`);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const updateUserSubscription = async (userId, subscriptionData) => {
  try {
    const response = await api.put(`/api/admin/users/${userId}/subscription`, subscriptionData);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const getWalletChecks = async () => {
  try {
    const response = await api.get('/api/admin/wallet-checks');
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const updateResultShown = async (checkId, resultShown) => {
  try {
    const response = await api.put(`/api/admin/wallet-checks/${checkId}/result-shown`, { resultShown });
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const plantResult = async (resultData) => {
  try {
    const response = await api.post('/api/admin/plant-result', resultData);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const getTotalAmounts = async () => {
  try {
    const response = await api.get('/api/admin/wallet-checks/total-amounts');
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const createInvoice = async (planData) => {
  try {
    const response = await api.post('/api/auth/create-invoice', planData);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const registerUser = async (userData) => {
  try {
    const response = await api.post('/api/auth/register', userData);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const activateUser = async (tempUserId) => {
  try {
    const response = await api.post('/api/auth/activate-user', { tempUserId });
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const checkPaymentStatus = async (invoiceId) => {
  try {
    const response = await api.get(`/api/auth/check-payment-status/${invoiceId}`);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const fetchUnseenResults = async () => {
  try {
    const response = await api.get('/api/wallet-check/results-with-balance-unseen', {
      params: { showResults: false }
    });
    return response.data.results;
  } catch (error) {
    return handleError(error);
  }
};

export const markResultAsSeen = async (resultId) => {
  try {
    const response = await api.patch(`/api/wallet-check/results-with-balance-unseen/${resultId}/seen`);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const updateUserPayment = async (userId, paymentData) => {
  try {
    const response = await api.put(`/api/users/${userId}/payment`, paymentData);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const fetchUserAffiliateStatus = async (token) => {
  try {
    const response = await api.get('/api/affiliate/user-affiliate-status', {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching user affiliate status:', error);
    throw error;
  }
};

export const becomeAffiliate = async (token, type) => {
  try {
    const response = await api.post('/api/affiliate/become-affiliate', 
      { type },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
    console.error('Error becoming an affiliate:', error);
    throw error;
  }
};

export const fetchAffiliateSales = async (token) => {
  try {
    const response = await api.get('/api/affiliate/affiliate-sales', {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching affiliate sales:', error);
    throw error;
  }
};

export const updatePayoutWallet = async (token, payoutWallet) => {
  try {
    const response = await api.post(`${API_URL}/api/affiliate//update-payout-wallet`, { payoutWallet }, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error('Error updating payout wallet:', error);
    throw error;
  }
};

export const deletePayoutWallet = async (token) => {
  try {
    const response = await api.delete(`${API_URL}/api/affiliate/delete-payout-wallet`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting payout wallet:', error);
    throw error;
  }
};

