// App.js
import React, { useState, useEffect, createContext, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import AuthContainer from './components/AuthContainer';
import WalletChecker from './components/WalletChecker';
import Admin from './admin/Admin';
import { fetchUserData, logout } from './utils/Api';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { jwtDecode } from "jwt-decode";
import '@fontsource/roboto/300.css'; // Light
import '@fontsource/roboto/400.css'; // Regular
import '@fontsource/roboto/500.css'; // Medium
import '@fontsource/roboto/700.css'; // Bold
import { createAppTheme } from './utils/Theme';
import Renewal from './components/Renewal'; 

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);


// Create a theme instance (unchanged)
/* const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
    background: {
      default: 'rgba(245, 245, 245, 0.7)', // semi-transparent background
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
}); */


// RegistrationWrapper Component
const RegistrationWrapper = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const referralCode = searchParams.get('referralCode');

  return (
    <AuthContainer 
      initialMode="register" 
      referralCode={referralCode} 
    />
  );
};

function App() {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [darkMode, setDarkMode] = useState(() => {
    const savedMode = localStorage.getItem('darkMode');
    return savedMode ? JSON.parse(savedMode) : true;
  });
  const theme = createAppTheme(darkMode);

  const decodeToken = (token) => {
    try {
      return jwtDecode(token);
    } catch (error) {
      console.error('Invalid token:', error);
      return null;
    }
  };

  const isTokenExpired = (decoded) => {
    if (!decoded || !decoded.exp) return true;
    const currentTime = Date.now() / 1000;
    return decoded.exp < currentTime;
  };

  useEffect(() => {
    const initializeAuth = async () => {
      if (token) {
        const decoded = decodeToken(token);
        if (isTokenExpired(decoded)) {
          await handleLogout();
        } else {
          try {
            const userData = await fetchUserData(token);
            setUser(userData);
          } catch (error) {
            console.error('Error fetching user data:', error);
            await handleLogout();
          }
        }
      }
      setLoading(false);
    };

    initializeAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const handleAuthenticated = async (newToken, message = 'Logged in successfully') => {
    localStorage.setItem('token', newToken);
    setToken(newToken);
    try {
      const userData = await fetchUserData(newToken);
      setUser(userData);
      setSnackbar({ open: true, message, severity: 'success' });
    } catch (error) {
      console.error('Error fetching user data after authentication:', error);
      await handleLogout();
      setSnackbar({ open: true, message: 'Error fetching user data. Please try again.', severity: 'error' });
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error('Logout error:', error);
    } finally {
      localStorage.removeItem('token');
      setToken(null);
      setUser(null);
      setSnackbar({ open: true, message: 'Logged out successfully.', severity: 'info' });
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const authContextValue = {
    token,
    user,
    handleAuthenticated,
    handleLogout,
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  // Create a wrapper component for Renewal to access location state
const RenewalWrapper = () => {
  const location = useLocation();
  const { username, email, referralCode } = location.state || {};

  // Redirect to home if necessary data is missing
  if (!username || !email) {
    return <Navigate to="/" replace />;
  }

  return <Renewal username={username} email={email} referralCode={referralCode} />;
};
  
  return (
    <AuthContext.Provider value={authContextValue}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {/* Background Image */}
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: -1,
            backgroundImage: 'url(/background.png)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            filter: 'brightness(0.8)',
          }}
        />
        <Router>
          <Container 
            maxWidth="sm" 
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              minHeight: '100vh',
              position: 'relative',
              zIndex: 1,
              padding: 0,
            }}
          >
            <Routes>
              <Route 
                path="/" 
                element={token && user ? <Navigate to="/wallet-checker" replace /> : <AuthContainer />} 
              />
              
              <Route 
                path="/registration" 
                element={token && user ? <Navigate to="/wallet-checker" replace /> : <RegistrationWrapper />} 
              />
              
              {/* Updated Renewal Route */}
              <Route 
                path="/renewal" 
                element={<RenewalWrapper />} 
              />
              
              <Route 
                path="/wallet-checker" 
                element={
                  token && user ? 
                    <WalletChecker token={token} user={user} onLogout={handleLogout} /> : 
                    <Navigate to="/" replace />
                } 
              />
              
              <Route 
                path="/admin" 
                element={
                  token && user && user.isAdmin ? 
                    <Admin token={token} user={user} /> : 
                    <Navigate to="/" replace />
                } 
              />
            </Routes>
          </Container>
        </Router>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </ThemeProvider>
    </AuthContext.Provider>
  );
}

export default App;
