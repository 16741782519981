import React, { useState, useEffect } from 'react';
import { Box, IconButton, Typography, CircularProgress, Fade, useTheme, useMediaQuery } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';

const NetworkSelector = ({ networks, selectedNetworks, onToggle, isChecking, isGenerating, progress, isRunning }) => {
  const [completedNetworks, setCompletedNetworks] = useState({});
  const [checkmarkTimers, setCheckmarkTimers] = useState({});
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (!isRunning && isGenerating && !isChecking) {
      // Reset completed networks when isGenerating becomes true
      setCompletedNetworks({});
      setCheckmarkTimers({});
    }
  }, [isRunning, isGenerating, isChecking]);

  useEffect(() => {
    if (!isChecking && !isRunning && !isGenerating) {
      const newTimers = {};
      selectedNetworks.forEach(network => {
        if (!completedNetworks[network]) {
          const delay = Math.random() * 2000; // Random delay up to 2 seconds
          newTimers[network] = setTimeout(() => {
            setCompletedNetworks(prev => ({ ...prev, [network]: true }));
          }, delay);
        }
      });
      setCheckmarkTimers(newTimers);
    }

    return () => {
      // Clear all timers when the component unmounts or when isChecking becomes false
      Object.values(checkmarkTimers).forEach(timer => clearTimeout(timer));
    };
  }, [isChecking, selectedNetworks, isRunning, isGenerating ]);

  const handleToggle = (network) => {
    console.log('NetworkSelector: toggling network', network);
    onToggle(network);
  };

  return (
    <Box sx={{ width: '100%', mb: 2 }}>
      <Box 
        sx={{ 
          display: 'grid',
          gridTemplateColumns: isSmallScreen ? 'repeat(5, 1fr)' : 'repeat(10, 1fr)',
          gridGap: '2px',
        }}
      >
        {networks.map((network) => {
          const isSelected = selectedNetworks.includes(network);
          const showCheck = completedNetworks[network];

          return (
            <Box 
              key={network} 
              sx={{ 
                width: '100%',
                position: 'relative',
                '&::before': {
                  content: '""',
                  display: 'block',
                  paddingTop: '100%', // 1:1 Aspect Ratio
                },
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '4px',
                }}
              >
                <IconButton 
                  onClick={() => onToggle(network)}
                  disabled={isChecking}
                  sx={{
                    width: '100%',
                    height: isSmallScreen ? '70%' : '80%',
                    padding: 0,
                    backgroundColor: isSelected ? 'action.selected' : 'transparent',
                    borderRadius: '50%',
                    filter: isSelected ? 'none' : 'grayscale(100%)',
                    boxShadow: isSelected ? '0 0 0 2px rgba(0, 123, 255, 0.5)' : 'none',
                    transition: 'all 0.3s',
                    position: 'relative',
                  }}
                >
                  <img 
                    src={`/${network}.png`} 
                    alt={network} 
                    style={{ 
                      width: '100%', 
                      height: '100%', 
                      objectFit: 'contain',
                      opacity: isChecking && isSelected ? 0.3 : 1,
                      transition: 'opacity 0.3s',
                    }} 
                  />
                  {isChecking && isSelected && !showCheck && (
                    <CircularProgress
                      size={isSmallScreen ? 24 : 40}
                      thickness={4}
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: isSmallScreen ? '-12px' : '-20px',
                        marginLeft: isSmallScreen ? '-12px' : '-20px',
                      }}
                    />
                  )}
                  <Fade in={showCheck} timeout={100}>
                    <CheckCircle 
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        color: 'success.main',
                        fontSize: isSmallScreen ? 24 : 40,
                      }}
                    />
                  </Fade>
                </IconButton>
                <Typography 
                  variant="caption" 
                  sx={{ 
                    textAlign: 'center',
                    width: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    fontSize: isSmallScreen ? '0.6rem' : '0.7rem',
                    lineHeight: 1,
                    paddingBottom: '15px',
                  }}
                >
                  {network}
                </Typography>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default NetworkSelector;