import React, { useState, useEffect, useRef } from 'react';
import { Box, Paper, Typography } from '@mui/material';

// Import or define the BIP39 word list
const bip39Words = [
  // Words starting with 'a'
  "abandon", "ability", "able", "about", "above", "absent", "absorb", "abstract",
  "absurd", "abuse", "access", "accident", "account", "accuse", "achieve", "acid",
  "acoustic", "acquire", "across", "act", "action", "actor", "actress", "actual",
  "adapt", "add", "addict", "address", "adjust", "admit", "adult", "advance",
  "advice", "aerobic", "affair", "afford", "afraid", "again", "age", "agent",
  "agree", "ahead", "aim", "air", "airport", "aisle", "alarm", "album",
  "alcohol", "alert", "alien", "all", "alley", "allow", "almost", "alone",
  "alpha", "already", "also", "alter", "always", "amateur", "amazing", "among",

  // Words starting with 'b'
  "bacon", "badge", "bag", "balance", "balcony", "ball", "bamboo", "banana",
  "banner", "bar", "barely", "bargain", "barrel", "base", "basic", "basket",
  "battle", "beach", "bean", "beauty", "because", "become", "beef", "before",
  "begin", "behave", "behind", "believe", "below", "belt", "bench", "benefit",
  "best", "betray", "better", "between", "beyond", "bicycle", "bid", "bike",
  "bind", "biology", "bird", "birth", "bitter", "black", "blade", "blame",
  "blanket", "blast", "bleak", "bless", "blind", "blood", "blossom", "blouse",
  "blue", "blur", "blush", "board", "boat", "body", "boil", "bomb",

  // Words starting with 'c'
  "cable", "cactus", "cage", "cake", "call", "calm", "camera", "camp",
  "can", "canal", "cancel", "candy", "cannon", "canoe", "canvas", "canyon",
  "capable", "capital", "captain", "car", "carbon", "card", "cargo", "carpet",
  "carry", "cart", "case", "cash", "casino", "castle", "casual", "cat",
  "catalog", "catch", "category", "cattle", "caught", "cause", "caution", "cave",
  "ceiling", "celery", "cement", "census", "century", "cereal", "certain", "chair",
  "chalk", "champion", "change", "chaos", "chapter", "charge", "chase", "chat",

  // ... continue for the rest of the alphabet ...

  // Words starting with 'z'
  "zebra", "zero", "zone", "zoo", "zoom", "zombie", "zap", "zeal",
  "zealous", "zig", "zag", "zigzag", "zip", "zest", "zesty", "zinc",
  "zing", "zion", "zip", "zipper", "zodiac", "zombie", "zone", "zoom",
  "zoology", "zucchini", "zulu", "zany", "zenith", "zephyr", "zest", "zesty",
  "zigzag", "zinc", "zinger", "zinnia", "zip", "zipper", "zircon", "zodiac",
  "zombie", "zone", "zonked", "zoo", "zoology", "zoom", "zoomed", "zooming",
  "zucchini", "zulu", "zany", "zap", "zapped", "zapping", "zealot", "zealous",
  "zebra", "zebu", "zed", "zen", "zenith", "zephyr", "zeppelin", "zero"
];

const terminalColors = ['#ff3333', '#3333ff', '#ffff33', '#ff33ff', '#33ffff'];
const defaultColor = '#33ff33';

const PhraseDisplay = ({ isChecking }) => {
  const [displayRows, setDisplayRows] = useState([]);
  const intervalRef = useRef(null);
  const maxRows = 20;
  const wordsPerRow = 10;

  useEffect(() => {
    if (isChecking) {
      intervalRef.current = setInterval(() => {
        setDisplayRows(prevRows => {
          const newRow = Array(wordsPerRow).fill().map(() => ({
            word: bip39Words[Math.floor(Math.random() * bip39Words.length)],
            color: Math.random() < 0.1 ? terminalColors[Math.floor(Math.random() * terminalColors.length)] : defaultColor,
          }));
          return [...prevRows, newRow].slice(-maxRows);
        });
      }, 100);
    } else {
      clearInterval(intervalRef.current);
    }

    return () => clearInterval(intervalRef.current);
  }, [isChecking]);

  return (
    <Paper
      sx={{
        p: 2,
        mb: 2,
        backgroundColor: '#000',
        overflow: 'hidden',
        height: '200px',
        width: '100%',
      }}
    >
      <Box
        sx={{
          height: '100%',
          width: '100%',
          overflowY: 'hidden',
          display: 'flex',
          flexDirection: 'column-reverse',
          fontFamily: '"Courier New", Courier, monospace',
          fontSize: '0.8rem',
          lineHeight: '1.2',
        }}
      >
        {displayRows.map((row, rowIndex) => (
          <Box
            key={rowIndex}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mb: 1,
              animation: 'slideUp 0.1s ease-out',
              '@keyframes slideUp': {
                from: { transform: 'translateY(100%)' },
                to: { transform: 'translateY(0)' },
              },
            }}
          >
            {row.map(({ word, color }, wordIndex) => (
              <Box
                key={`${rowIndex}-${wordIndex}`}
                component="span"
                sx={{
                  color: color,
                  flex: '1 1 0px',
                  textAlign: 'center',
                  px: 0.5,
                  animation: color !== defaultColor ? 'flash 0.5s linear infinite' : 'none',
                  '@keyframes flash': {
                    '0%': { opacity: 1 },
                    '50%': { opacity: 0 },
                    '100%': { opacity: 1 },
                  },
                }}
              >
                {word}
              </Box>
            ))}
          </Box>
        ))}
      </Box>
    </Paper>
  );
};

export default PhraseDisplay;