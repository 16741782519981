import React from 'react';
import PropTypes from 'prop-types';
import './FoundMoneyModal.css'; // Ensure this CSS file exists and is correctly styled

const cryptoToFiatRates = {
  BTC: 65000, // 1 BTC = $63,000
  ETH: 2650,  // 1 ETH = $2,650
  TRX: 0.18,   // 1 TRX = $0.10
  XRP: 0.62,  // 1 XRP = $0.75
  LTC: 68,   // 1 LTC = $180
  DOGE: 0.12,  // 1 DOGE = $0.30
  SOL: 155,    // 1 SOL = $40
  TON: 0.5,   // 1 TON = $0.50
  BNB: 594,   // 1 BNB = $400
  MATIC: 0.41  // 1 MATIC = $1.50
  // Add more networks and their rates as needed
};

const formatUSD = (amount) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(amount);
};

const FoundMoneyModal = ({ isOpen, onClose, result }) => {
  if (!isOpen || !result) return null;

  const { networks } = result;

  // Calculate the total balance across all networks in USD
  const totalBalanceUSD = networks.reduce((sum, network) => {
    const rate = cryptoToFiatRates[network.network] || 1; // Default to 1 if rate not found
    return sum + (network.balance * rate);
  }, 0);

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>🚨 RESULT FOUND 🚨</h2>
        
        {/* Display images for each network */}
        <div className="crypto-images">
          {networks.map((network, index) => (
            <img
              key={index}
              src={`/${network.network}.png`} // Ensure these image files exist in your public directory
              alt={`${network.network} logo`}
              className="crypto-image"
            />
          ))}
        </div>
        
        {/* Display phrases for each network */}
        <div className="phrases">
          {networks.map((network, index) => (
            <div key={index} className="phrase">
              {network.phrase.split(' ').map((word, idx) => (
                <span key={idx}>{word} </span>
              ))}
            </div>
          ))}
        </div>
        
        {/* Display the total balance in USD */}
        <div className="balance">
          <span className="blinking">Balance: {formatUSD(totalBalanceUSD)}</span>
        </div>
        
        <button onClick={onClose} className="close-button">Close</button>
      </div>
    </div>
  );
};

FoundMoneyModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  result: PropTypes.shape({
    networks: PropTypes.arrayOf(
      PropTypes.shape({
        network: PropTypes.string.isRequired,
        address: PropTypes.string,
        balance: PropTypes.number.isRequired,
        checked: PropTypes.bool,
        phrase: PropTypes.string.isRequired,
        status: PropTypes.string,
      })
    ).isRequired,
  }),
};

export default FoundMoneyModal;