// Registration.js
import React, { useState, useEffect } from 'react';
import { 
  Button, 
  TextField, 
  Card, 
  CardContent, 
  Typography, 
  Alert, 
  CircularProgress,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Snackbar,
  Box,
  InputAdornment,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';

// Import Icons
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard'; // Using as Referral Icon

import { createInvoice, checkPaymentStatus, registerUser } from '../utils/Api';
import { useNavigate } from 'react-router-dom';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { useAuth } from '../App'; // Correctly import useAuth

const Registration = ({ onRegistrationComplete, referralCode = '' }) => {
  const [step, setStep] = useState(1);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [referralCodeState, setReferralCodeState] = useState(referralCode);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState([]); // Changed to array for multiple errors
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [deviceId, setDeviceId] = useState('');
  const [token, setToken] = useState(null);
  const [tempUserId, setTempUserId] = useState(null); // New state for tempUserId
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const { handleAuthenticated } = useAuth(); // Use handleAuthenticated from context
  const navigate = useNavigate();

  useEffect(() => {
    const generateDeviceId = async () => {
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      setDeviceId(result.visitorId);
    };

    generateDeviceId();
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      if (token) {
        try {
          const response = await fetch('/api/auth/user', {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          if (!response.ok) {
            throw new Error('Failed to fetch user data');
          }
          const userData = await response.json();
          // Optionally, handle user data (e.g., store in context or state)
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchUserData();
  }, [token]);

  const plans = [
    { id: '3days', label: '3 Days Access', price: 150, days: 3 },
    { id: '14days', label: '14 Days Access', price: 600, days: 14 },
    { id: '30days', label: '30 Days Access', price: 1000, days: 30 },
  ];

  const handlePlanChange = (event) => {
    setSelectedPlan(event.target.value);
  };

  const handleCheckPaymentStatus = async (invoiceId) => {
    try {
      const response = await checkPaymentStatus(invoiceId);
      
      if (response.status === 'complete') {
        setConfirmationMessage('Registration and payment completed successfully');
        setStep(3);
        // Use handleAuthenticated from context with the received token
        handleAuthenticated(response.token, 'Registration and payment completed successfully');
        onRegistrationComplete(response.token, 'User registered and logged in successfully');
      } else if (response.status === 'expired') {
        setError(['Payment expired. Please try again.']);
        setStep(1);
      } else {
        setError(['Payment not completed. Please try again.']);
        setStep(1);
      }
    } catch (error) {
      console.error('Error checking payment status:', error);
      setError(['Error verifying payment. Please contact support.']);
      setStep(1);
    } finally {
      setIsLoading(false);
    }
  };


  const handlePayment = async () => {
    if (!selectedPlan) {
      setError(['Please select a plan']);
      return;
    }

    setIsLoading(true);
    setError([]);

    try {
      const selectedPlanDetails = plans.find(plan => plan.id === selectedPlan);
      
      const response = await createInvoice({
        plan: selectedPlan,
        price: selectedPlanDetails.price,
        referralCode: referralCodeState, // Use state referralCode
        buyer_email: email,
        notes: `Phone: ${phoneNumber}, Username: ${username}`
      });

      const { invoiceId } = response;

      window.bitcart.onModalWillEnter(() => {
        console.log('Payment modal opened');
      });

      window.bitcart.onModalWillLeave(() => {
        console.log('Payment modal closed');
        handleCheckPaymentStatus(invoiceId);
      });

      window.bitcart.showInvoice(invoiceId);
    } catch (error) {
      console.error('Error creating invoice:', error);
      // Handle multiple error messages if available
      if (error.response?.data?.errors) {
        setError(error.response.data.errors);
      } else {
        setError(['Failed to initiate payment. Please try again.']);
      }
      setIsLoading(false);
    }
  };

  const handleRegistration = async (e) => {
    e.preventDefault();
    // Basic client-side validation
    const validationErrors = [];
    if (username.length < 3) validationErrors.push('Username must be at least 3 characters long.');
    if (password.length < 6) validationErrors.push('Password must be at least 6 characters long.');
    // Add more validations as needed

    if (validationErrors.length > 0) {
      setError(validationErrors);
      return;
    }

    setIsLoading(true);
    setError([]);

    try {
      const response = await registerUser({
        username,
        password,
        deviceId,
        email,
        phoneNumber,
        plan: selectedPlan,
        referralCode: referralCodeState // Use state referralCode
      });

      if (response.token) {
        // Case 1: Immediate login and payment
        setToken(response.token);
        setStep(2);
        handlePayment();
      } else if (response.tempUserId) {
        // Case 2: Registration successful, payment required
        setTempUserId(response.tempUserId);
        setStep(2);
        handlePayment();
      } else if (response.errors) {
        // Handle multiple errors
        setError(response.errors);
      } else {
        // Unexpected response
        throw new Error(response.message || 'Registration failed');
      }
    } catch (error) {
      console.error('Error during registration:', error);
      // Handle both array and single error message
      if (error.response?.data?.errors) {
        setError(error.response.data.errors);
      } else {
        setError([error.message || 'Registration failed. Please try again.']);
      }
      setIsLoading(false);
    }
  };

  const handleConfirmation = () => {
    navigate('/wallet-checker');
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <>
            <Typography variant="h5" component="div" gutterBottom align="center">
              Create Your Account
            </Typography>
            <form onSubmit={handleRegistration}>
              <TextField
                label="Username"
                variant="outlined"
                fullWidth
                margin="normal"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon sx={{ color: 'gold' }} /> {/* Gold icon */}
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Password"
                type="password"
                variant="outlined"
                fullWidth
                margin="normal"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon sx={{ color: 'gold' }} /> {/* Gold icon */}
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Email"
                type="email"
                variant="outlined"
                fullWidth
                margin="normal"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon sx={{ color: 'gold' }} /> {/* Gold icon */}
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Phone Number"
                variant="outlined"
                fullWidth
                margin="normal"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneIcon sx={{ color: 'gold' }} /> {/* Gold icon */}
                    </InputAdornment>
                  ),
                }}
              />
           <FormControl fullWidth margin="normal">
  <InputLabel id="subscription-plan-label">Subscription Options</InputLabel>
  <Select
    labelId="subscription-plan-label"
    id="subscription-plan"
    value={selectedPlan}
    label="Subscription Options"
    onChange={handlePlanChange}
  >
    {plans.map((plan) => (
     <MenuItem key={plan.id} value={plan.id}>
     <Box sx={{ display: 'flex', alignItems: 'center' }}>
       <PersonIcon sx={{ mr: 1, color: 'gold' }} />
       <Typography>{`${plan.label} - $${plan.price}`}</Typography>
     </Box>
   </MenuItem>
    ))}
  </Select>
</FormControl>
              {/* Conditionally render Referral Code field */}
              {!referralCode && (
                <TextField
                  label="Referral Code"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={referralCodeState}
                  onChange={(e) => setReferralCodeState(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CardGiftcardIcon sx={{ color: 'gold' }} /> {/* Gold icon */}
                      </InputAdornment>
                    ),
                  }}
                />
              )}

              <Button
                type="submit"
                variant="contained"
                fullWidth
                disabled={isLoading}
                sx={{ 
                  mt: 2, 
                  py: 1,
                  backgroundColor: '#FFD700', // Gold color
                  color: '#000', // Black text for contrast
                  '&:hover': {
                    backgroundColor: '#FFC107', // Darker gold on hover
                  },
                }}
              >
                {isLoading ? <CircularProgress size={24} /> : 'Register and Proceed to Payment'}
              </Button>
            </form>
          </>
        );
      case 2:
        return (
          <>
            <Typography variant="h5" component="div" gutterBottom align="center">
              Payment in Progress
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <CircularProgress />
            </Box>
            <Typography variant="body1" sx={{ mt: 2 }} align="center">
              Please complete the payment in the modal window.
            </Typography>
          </>
        );
      case 3:
        return (
          <>
            <Typography variant="h5" component="div" gutterBottom align="center">
              Registration Complete
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }} align="center">
              {confirmationMessage}
            </Typography>
            <Button
              variant="contained"
              fullWidth
              onClick={handleConfirmation}
              sx={{ 
                mt: 2, 
                py: 1,
                backgroundColor: '#FFD700', // Gold color
                color: '#000', // Black text for contrast
                '&:hover': {
                  backgroundColor: '#FFC107', // Darker gold on hover
                },
              }}
            >
              Continue
            </Button>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Box sx={{ width: '100%', maxWidth: 500, mx: 'auto', p: 3, bgcolor: 'background.paper', boxShadow: 3, borderRadius: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
        <img src="/cryptocraft.png" alt="WalletFinder Logo" style={{ width: '200px', height: 'auto' }} />
      </Box>
      {renderStep()}
      {/* Display multiple error messages */}
      {error.length > 0 && step !== 2 && error.map((err, index) => (
        <Alert key={index} severity="error" sx={{ mt: 2 }}>
          {err}
        </Alert>
      ))}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default Registration;
