import React, { useState } from 'react';
import { Tabs, Tab, Box, Paper, AppBar, Toolbar, IconButton, Typography, Menu, MenuItem, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import UserManagement from './UserManagement';
import SubscriptionManagement from './SubscriptionManagement';
import ResultManagement from './ResultManagement';

const AdminContainer = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  minHeight: '100vh',
  borderRadius: 0,
  display: 'flex',
  flexDirection: 'column',
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  flexGrow: 1,
  padding: theme.spacing(3),
  overflowX: 'auto',
  transition: 'opacity 0.3s ease-in-out',
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  '&.Mui-selected': {
    color: theme.palette.secondary.main,
  },
}));

const Admin = ({ token }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTabChange = (index) => {
    setActiveTab(index);
    handleClose();
  };

  const tabContent = [
    { label: "User Management", component: <UserManagement token={token} /> },
    { label: "Subscription Management", component: <SubscriptionManagement token={token} /> },
    { label: "Result Management", component: <ResultManagement token={token} /> },
  ];

  return (
    <AdminContainer elevation={0}>
      <AppBar position="static" color="primary">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Admin Panel
          </Typography>
          {isMobile ? (
            <>
              <IconButton
                size="large"
                edge="end"
                color="inherit"
                aria-label="menu"
                onClick={handleMenu}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {tabContent.map((tab, index) => (
                  <MenuItem key={index} onClick={() => handleTabChange(index)}>
                    {tab.label}
                  </MenuItem>
                ))}
              </Menu>
            </>
          ) : (
            <Tabs value={activeTab} onChange={handleChange} aria-label="admin tabs">
              {tabContent.map((tab, index) => (
                <StyledTab key={index} label={tab.label} />
              ))}
            </Tabs>
          )}
        </Toolbar>
      </AppBar>
      <ContentContainer>
        {tabContent[activeTab].component}
      </ContentContainer>
    </AdminContainer>
  );
};

export default Admin;