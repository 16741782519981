import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Paper, Button, Pagination } from '@mui/material';
import { Refresh, Delete, OpenInNew, ContentCopy, CheckCircleOutline } from '@mui/icons-material';
import { fetchWalletCheckResults } from '../utils/Api';

const ResultsModal = ({ open, onClose, token, clearResults }) => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [copiedPhrase, setCopiedPhrase] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showWinningOnly, setShowWinningOnly] = useState(false);

  useEffect(() => {
    if (open) {
      fetchResults();
    }
  }, [open, page, showWinningOnly]);

  const fetchResults = async () => {
    setLoading(true);
    setError(null);
    try {
      const data = await fetchWalletCheckResults({
        token,
        page,
        limit: 20,
        showWinning: showWinningOnly
      });
      setResults(data.results || []);
      setTotalPages(data.totalPages || 1);
    } catch (err) {
      console.error('Error fetching wallet check results:', err);
      setError('Failed to fetch results.');
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const toggleWinningResults = () => {
    setShowWinningOnly(!showWinningOnly);
    setPage(1);
  };

  const getExplorerUrl = (network, address) => {
    switch(network.toUpperCase()) {
      case 'BTC':
        return `https://blockchair.com/bitcoin/address/${address}`;
      case 'ETH':
        return `https://etherscan.io/address/${address}`;
      case 'LTC':
        return `https://blockchair.com/litecoin/address/${address}`;
      case 'BCH':
        return `https://blockchair.com/bitcoin-cash/address/${address}`;
      case 'DOGE':
        return `https://dogechain.info/address/${address}`;
      case 'DASH':
        return `https://chainz.cryptoid.info/dash/address.dws?${address}.htm`;
      case 'XRP':
        return `https://xrpscan.com/account/${address}`;
      case 'XLM':
        return `https://stellar.expert/explorer/public/account/${address}`;
      case 'ADA':
        return `https://cardanoscan.io/address/${address}`;
      case 'DOT':
        return `https://polkascan.io/polkadot/account/${address}`;
      case 'SOL':
        return `https://explorer.solana.com/address/${address}`;
      case 'AVAX':
        return `https://avascan.info/blockchain/c/address/${address}`;
      case 'MATIC':
      case 'POLYGON':
        return `https://polygonscan.com/address/${address}`;
      case 'ALGO':
        return `https://algoexplorer.io/address/${address}`;
      case 'XTZ':
        return `https://tzstats.com/${address}`;
      case 'ZEC':
        return `https://chainz.cryptoid.info/zec/address.dws?${address}.htm`;
      case 'EOS':
        return `https://bloks.io/account/${address}`;
      case 'XMR':
        return `https://localmonero.co/blocks/search/${address}`;
      case 'BSC':
      case 'BNB':
        return `https://bscscan.com/address/${address}`;
      case 'TRX':
        return `https://tronscan.org/#/address/${address}`;
      case 'NEO':
        return `https://neo.tokenview.com/en/address/${address}`;
      case 'ATOM':
        return `https://www.mintscan.io/cosmos/account/${address}`;
      case 'THETA':
        return `https://explorer.thetatoken.org/account/${address}`;
      case 'VET':
        return `https://explore.vechain.org/accounts/${address}`;
      case 'EGLD':
        return `https://explorer.elrond.com/accounts/${address}`;
      case 'HBAR':
        return `https://hashscan.io/#/mainnet/account/${address}`;
      case 'XDC':
        return `https://explorer.xinfin.network/addr/${address}`;
      case 'FIL':
        return `https://filfox.info/en/address/${address}`;
      case 'NEAR':
        return `https://explorer.near.org/accounts/${address}`;
      case 'FLOW':
        return `https://flowscan.org/account/${address}`;
      // Add more networks as needed
      default:
        // For networks not explicitly listed, we'll use blockchair as a fallback
        // Note that this may not work for all networks
        return `https://blockchair.com/${network.toLowerCase()}/address/${address}`;
    }
  };



  const copyPhrase = (phrase) => {
    navigator.clipboard.writeText(phrase);
    setCopiedPhrase(phrase);
    setTimeout(() => setCopiedPhrase(''), 2000);
  };

  const renderControls = () => (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
      <Button
        variant="contained"
        onClick={() => {
          setShowWinningOnly(false);
          setPage(1);
        }}
        sx={{ mr: 1 }}
      >
        VIEW ALL RESULTS
      </Button>
      <Button
        variant="contained"
        onClick={toggleWinningResults}
        sx={{ bgcolor: 'gold', color: 'black' }}
      >
        SHOW WINNING RESULTS
      </Button>
    </Box>
  );

  const renderSummary = () => {
    const totalWalletChecks = results.length;
    const totalChecked = results.reduce((sum, result) => 
      sum + result.networks.filter(n => n.checked).length, 0);

    return (
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="body2">
          Total Checks: {totalWalletChecks} | Completed: {totalChecked}
        </Typography>
        <IconButton onClick={fetchResults} disabled={loading} size="small">
          <Refresh fontSize="small" />
        </IconButton>
      </Box>
    );
  };

  const renderDetailedResults = () => {
    if (loading) return <Typography variant="body2">Loading...</Typography>;
    if (error) return <Typography variant="body2" color="error">{error}</Typography>;
    if (!results.length) return <Typography variant="body2">No results to display.</Typography>;

    return results.flatMap((walletCheck) => 
      walletCheck.networks.map((network) => (
        <Box key={network._id} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            <img 
              src={`/${network.network.toUpperCase()}.png`}
              alt={network.network}
              style={{ width: 24, height: 24, marginRight: 8 }}
            />
            <Typography variant="body2">
              {network.balance} {network.network}
            </Typography>
          </Box>
          <IconButton 
            size="small" 
            onClick={() => copyPhrase(network.phrase)}
            sx={{ mr: 1 }}
          >
            <ContentCopy fontSize="small" color={copiedPhrase === network.phrase ? "primary" : "inherit"} />
          </IconButton>
          <IconButton 
            size="small" 
            onClick={() => window.open(getExplorerUrl(network.network, network.address), '_blank')}
          >
            <OpenInNew fontSize="small" />
          </IconButton>
        </Box>
      ))
    );
  };

  if (!open) return null;

  return (
    <Box sx={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      bgcolor: 'background.paper',
      display: 'flex',
      flexDirection: 'column',
      p: 2,
    }}>
      <Typography variant="h6" sx={{ mb: 2, textAlign: 'center' }}>Results</Typography>
      
      {renderControls()}
      
      <Paper elevation={2} sx={{ p: 2, mb: 2, flexGrow: 1, overflowY: 'auto', bgcolor: 'grey.900' }}>
        {renderSummary()}
        <Typography variant="subtitle1" sx={{ mb: 1 }}>Detailed Results</Typography>
        {renderDetailedResults()}
      </Paper>
      
      <Pagination 
        count={totalPages} 
        page={page} 
        onChange={handlePageChange} 
        sx={{ mb: 2, display: 'flex', justifyContent: 'center' }}
      />
      
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={onClose}
          startIcon={<CheckCircleOutline />}
          fullWidth
          sx={{ bgcolor: 'rgb(138, 180, 248)', color: 'black' }}
        >
          CLOSE
        </Button>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<Delete />}
          onClick={clearResults}
          fullWidth
          sx={{ bgcolor: 'rgb(242, 139, 130)', color: 'black' }}
        >
          CLEAR
        </Button>
      </Box>
    </Box>
  );
};

export default ResultsModal;