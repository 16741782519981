import React, { useState, useEffect } from 'react';
import { 
  Button, 
  TextField, 
  Checkbox, 
  FormControlLabel, 
  Typography, 
  Alert, 
  CircularProgress,
  Box,
  InputAdornment // Import InputAdornment
} from '@mui/material';

// Import Icons
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';

import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { login } from '../utils/Api';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../App';

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [notification, setNotification] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [rememberMe, setRememberMe] = useState(false);
  const [deviceId, setDeviceId] = useState('');
  const navigate = useNavigate();
  const { handleAuthenticated } = useAuth();

  useEffect(() => {
    const generateDeviceId = async () => {
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      setDeviceId(result.visitorId);
    };

    generateDeviceId();
  }, []);

  const validateInputs = () => {
    const newErrors = {};
    if (username.length < 3) {
      newErrors.username = 'Username must be at least 3 characters long';
    }
    if (password.length < 6) {
      newErrors.password = 'Password must be at least 6 characters long';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateInputs()) return;
  
    setIsLoading(true);
    setNotification(null);
  
    try {
      const response = await login(username, password, deviceId, rememberMe);
      
      if (response.token) {
        if (response.paymentRequired) {
          // Subscription expired, but user is still active
          setNotification({ 
            type: 'warning', 
            message: 'Your subscription has expired. Please renew your subscription to continue full access.'
          });
          
          console.log('Navigating to registration with state:', {
            username: response.username,
            email: response.email,
            referralCode: response.referralCode,
            isRenewal: true
          });
  
          // Redirect to registration page for renewal
          navigate('/renewal', {
            state: { 
              username: response.username,
              email: response.email,
              referralCode: response.referralCode,
              isRenewal: true
            }
          });
        } else {
          // Normal login success
          handleAuthenticated(response.token, 'Login successful!');
          onLogin(response.token);
        }
      } else {
        throw new Error('Login failed. Please check your credentials and try again.');
      }
    } catch (error) {
      console.error('Login error:', error);
      setNotification({ 
        type: 'error', 
        message: error.message || 'An error occurred. Please try again.'
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box 
      sx={{ 
        width: '100%', 
        maxWidth: 400, 
        mx: 'auto', 
        p: 3, 
        bgcolor: 'background.paper', 
        boxShadow: 3, 
        borderRadius: 2 
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
        <img src="/cryptocraft.png" alt="WalletFinder Logo" style={{ width: '200px', height: 'auto' }} />
      </Box>
      <Typography variant="h5" component="div" gutterBottom align="center">
        Login
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Username"
          variant="outlined"
          fullWidth
          margin="normal"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          error={!!errors.username}
          helperText={errors.username}
          required
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonIcon />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={!!errors.password}
          helperText={errors.password}
          required
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon />
              </InputAdornment>
            ),
          }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={rememberMe}
              onChange={(e) => setRememberMe(e.target.checked)}
              color="primary"
            />
          }
          label="Remember me"
        />
        <Button
          type="submit"
          variant="contained"
          fullWidth
          disabled={isLoading}
          sx={{ 
            py: 1, 
            fontSize: '1rem',
            backgroundColor: '#FFD700',
            color: '#000',
            '&:hover': {
              backgroundColor: '#FFC107',
            },
          }}
        >
          {isLoading ? <CircularProgress size={24} /> : 'LOGIN'}
        </Button>
      </form>
      {notification && (
        <Alert severity={notification.type} sx={{ mt: 2 }}>
          {notification.message}
        </Alert>
      )}
      <Box sx={{ mt: 3, textAlign: 'center' }}>
        <Typography variant="body2" color="text.secondary">
          © 2024 CryptoCraft. All rights reserved.
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
          We are 50% Robin Hood.
        </Typography>
      </Box>
    </Box>
  );
};

export default Login;