import { fetchUserData, generatePhrases, startWalletCheck, getWalletCheckResult } from './Api';
import { SUPPORTED_NETWORKS } from './Constants';

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

async function testWalletCheck(token) {
  console.log('Starting wallet check test');

  try {
    // Fetch user data
    console.log('Fetching user data...');
    const userData = await fetchUserData(token);
    console.log('User data:', userData);

    // Select networks (for this test, we'll use all supported networks)
    const selectedNetworks = SUPPORTED_NETWORKS;
    console.log('Selected networks:', selectedNetworks);

    // Generate phrases
    console.log('Generating phrases...');
    let phrases;
    try {
      phrases = await generatePhrases(token, selectedNetworks);
      console.log('Generated phrases:', phrases);
    } catch (error) {
      console.error('Error generating phrases:', error);
      throw error;
    }

    // Start wallet check
    console.log('Starting wallet check...');
    let walletCheckId;
    try {
      const response = await startWalletCheck(token, phrases, selectedNetworks);
      walletCheckId = response.walletCheckId;
      console.log('Wallet check started with ID:', walletCheckId);
    } catch (error) {
      console.error('Error starting wallet check:', error);
      throw error;
    }

    // Wait for results
    console.log('Waiting for results...');
    let results;
    let attempts = 0;
    const maxAttempts = 30; // Adjust as needed
    while (attempts < maxAttempts) {
      try {
        const data = await getWalletCheckResult(token, walletCheckId);
        console.log('Wallet check status:', data.status);
        if (data.status === 'completed') {
          results = data.results;
          break;
        } else if (data.status === 'error') {
          throw new Error('Wallet check failed: ' + data.error);
        }
        await sleep(2000); // Wait 2 seconds before checking again
        attempts++;
      } catch (error) {
        console.error('Error fetching wallet check result:', error);
        throw error;
      }
    }

    if (results) {
      console.log('Wallet check completed. Results:', results);
    } else {
      console.log('Wallet check timed out');
    }

  } catch (error) {
    console.error('Error during wallet check test:', error);
    throw error; // Re-throw the error so it can be caught by the calling function
  }
}

export default testWalletCheck;