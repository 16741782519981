// AuthContainer.js
import React, { useState, useEffect } from 'react';
import Login from './Login';
import Registration from './Registration';
import { Button, Box, CircularProgress, Alert, Paper } from '@mui/material';
import { useAuth } from '../App';

const AuthContainer = ({ initialMode = 'login', referralCode = '' }) => {
  const [isRegistering, setIsRegistering] = useState(initialMode === 'register');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const { handleAuthenticated } = useAuth();

  const handleLogin = async (token) => {
    setIsLoading(true);
    setError('');
    try {
      await handleAuthenticated(token);
    } catch (err) {
      setError('Login failed. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleRegistrationComplete = async (token, message) => {
    setIsLoading(true);
    setError('');
    try {
      if (token) {
        await handleAuthenticated(token, message);
      } else {
        setIsRegistering(false);
        setError('Registration successful. Please log in to complete the payment process.');
      }
    } catch (err) {
      setError('Registration failed. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const toggleAuthMode = () => {
    setIsRegistering(!isRegistering);
    setError('');
  };

  // If initialMode changes (e.g., navigating to /registration), update isRegistering
  useEffect(() => {
    setIsRegistering(initialMode === 'register');
  }, [initialMode]);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%', maxWidth: 400, mx: 'auto' }}>
      <Box sx={{ mb: 2, backgroundColor: 'transparent' }}>
        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        {isRegistering ? (
          <Registration 
            onRegistrationComplete={handleRegistrationComplete} 
            referralCode={referralCode}
          />
        ) : (
          <Login onLogin={handleLogin} />
        )}
      </Box>
      <Button
        onClick={toggleAuthMode}
        variant="contained"
        fullWidth
        sx={{ 
          py: 1, 
          fontSize: '1rem',
          backgroundColor: '#FFD700',
          color: '#000',
          '&:hover': {
            backgroundColor: '#FFC107',
          },
        }}
      >
        {isRegistering ? 'SWITCH TO LOGIN' : 'SWITCH TO REGISTER'}
      </Button>
    </Box>
  );
};

export default AuthContainer;