import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Menu, MenuItem, Switch, Button, Box } from '@mui/material';
import { ArrowBack, Menu as MenuIcon, Brightness4, Brightness7 } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import AffiliateModal from './AffiliateModal'; // Import the new AffiliateModal component

const TopBar = ({ user, days, onLogout, onDarkModeToggle, onOpenResults, darkMode, token }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [affiliateModalOpen, setAffiliateModalOpen] = useState(false);

  const handleMenu = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  
  const handleOpenResults = () => {
    onOpenResults();
    handleClose();
  };

  const handleOpenAffiliateModal = () => {
    setAffiliateModalOpen(true);
    handleClose();
  };

  return (
    <>
      <AppBar position="static" color="primary">
      <Toolbar sx={{ 
        flexWrap: 'nowrap', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        minHeight: '64px', // Set a fixed height for the toolbar
        padding: '0 16px' // Add horizontal padding
      }}>
        <IconButton edge="start" color="inherit" aria-label="back">
          <ArrowBack />
        </IconButton>
        
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center',
          flexGrow: 1,
          height: '100%'
        }}>
          <img 
            src="/cryptocraft.png" 
            alt="CryptoCraft Logo" 
            style={{ 
              width: 'auto', 
              height: '50px', // Adjust this value to fit your design
              maxWidth: '180px'
            }} 
          />
        </Box>

        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center'
        }}>
          <Typography 
            variant="body2" 
            component="div"
            sx={{ 
              mr: 1,
              display: { xs: 'none', sm: 'block' }
            }}
          >
            Days left: {days}
          </Typography>
          
          {/* {user && user.isAdmin && (
            <Button 
              color="inherit" 
              component={Link} 
              to="/admin"
              sx={{ mr: 1 }}
            >
              Admin
            </Button>
          )} */}
          
          <IconButton
            color="inherit"
            aria-label="menu"
            onClick={handleMenu}
          >
            <MenuIcon />
          </IconButton>
        </Box>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleOpenResults}>View Results</MenuItem>
          <MenuItem onClick={handleOpenAffiliateModal}>Affiliate Program</MenuItem>
          <MenuItem>
            Dark Mode
            <Switch
              checked={darkMode}
              onChange={onDarkModeToggle}
              icon={<Brightness7 />}
              checkedIcon={<Brightness4 />}
            />
          </MenuItem>
          <MenuItem onClick={onLogout}>Logout</MenuItem>
        </Menu>
      </Toolbar>
      </AppBar>
      <AffiliateModal 
        open={affiliateModalOpen} 
        onClose={() => setAffiliateModalOpen(false)}
        token={token}
      />
    </>
  );
};

export default TopBar;