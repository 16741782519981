import React, { useState, useEffect } from 'react';
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField,
  Select, MenuItem, FormControl, InputLabel, Typography, Grid, Card, CardContent, Box, useMediaQuery
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { getUsers, updateUserSubscription } from '../utils/Api';

const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
}));

const StyledForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
}));

const SubscriptionManagement = ({ token }) => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [subscriptionData, setSubscriptionData] = useState({
    months: 1,
    tier: 'basic',
    paymentAmount: 0,
    paymentDate: new Date().toISOString().split('T')[0],
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const fetchedUsers = await getUsers(token);
      setUsers(fetchedUsers);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleUpdateSubscription = async (e) => {
    e.preventDefault();
    if (!selectedUser) return;

    try {
      await updateUserSubscription(token, selectedUser._id, subscriptionData);
      fetchUsers();
      setSelectedUser(null);
      setSubscriptionData({
        months: 1,
        tier: 'basic',
        paymentAmount: 0,
        paymentDate: new Date().toISOString().split('T')[0],
      });
    } catch (error) {
      console.error('Error updating subscription:', error);
    }
  };

  const renderUserCard = (user) => (
    <StyledCard key={user._id}>
      <CardContent>
        <Typography variant="h6">{user.username}</Typography>
        <Typography variant="body2">Tier: {user.subscriptionTier}</Typography>
        <Typography variant="body2">Expires: {new Date(user.subscriptionExpiresAt).toLocaleDateString()}</Typography>
        <Typography variant="body2">Last Payment: ${user.lastPaymentAmount} on {new Date(user.lastPaymentDate).toLocaleDateString()}</Typography>
        <Box mt={2}>
          <Button variant="contained" color="primary" onClick={() => setSelectedUser(user)}>Modify Subscription</Button>
        </Box>
      </CardContent>
    </StyledCard>
  );

  const renderUserTable = () => (
    <TableContainer component={Paper} sx={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Username</TableCell>
            <TableCell>Subscription Tier</TableCell>
            <TableCell>Expires At</TableCell>
            <TableCell>Last Payment</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <TableRow key={user._id}>
              <TableCell>{user.username}</TableCell>
              <TableCell>{user.subscriptionTier}</TableCell>
              <TableCell>{new Date(user.subscriptionExpiresAt).toLocaleDateString()}</TableCell>
              <TableCell>${user.lastPaymentAmount} on {new Date(user.lastPaymentDate).toLocaleDateString()}</TableCell>
              <TableCell>
                <Button variant="contained" color="primary" onClick={() => setSelectedUser(user)}>Modify Subscription</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Box className="subscription-management">
      <Typography variant="h4" gutterBottom>Subscription Management</Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={selectedUser ? 6 : 12}>
          {isMobile || isTablet ? (
            <Grid container spacing={2}>
              {users.map(renderUserCard)}
            </Grid>
          ) : (
            renderUserTable()
          )}
        </Grid>
        {selectedUser && (
          <Grid item xs={12} md={6}>
            <StyledForm onSubmit={handleUpdateSubscription}>
              <Typography variant="h5" gutterBottom>Modify Subscription for {selectedUser.username}</Typography>
              <TextField
                label="Months to Add"
                type="number"
                value={subscriptionData.months}
                onChange={(e) => setSubscriptionData({ ...subscriptionData, months: parseInt(e.target.value) })}
                fullWidth
              />
              <FormControl fullWidth>
                <InputLabel>Subscription Tier</InputLabel>
                <Select
                  value={subscriptionData.tier}
                  onChange={(e) => setSubscriptionData({ ...subscriptionData, tier: e.target.value })}
                >
                  <MenuItem value="basic">Basic</MenuItem>
                  <MenuItem value="premium">Premium</MenuItem>
                  <MenuItem value="enterprise">Enterprise</MenuItem>
                </Select>
              </FormControl>
              <TextField
                label="Payment Amount"
                type="number"
                value={subscriptionData.paymentAmount}
                onChange={(e) => setSubscriptionData({ ...subscriptionData, paymentAmount: parseFloat(e.target.value) })}
                fullWidth
              />
              <TextField
                label="Payment Date"
                type="date"
                value={subscriptionData.paymentDate}
                onChange={(e) => setSubscriptionData({ ...subscriptionData, paymentDate: e.target.value })}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Box mt={2}>
                <Button type="submit" variant="contained" color="primary" sx={{ mr: 2 }}>Update Subscription</Button>
                <Button variant="contained" onClick={() => setSelectedUser(null)}>Cancel</Button>
              </Box>
            </StyledForm>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default SubscriptionManagement;