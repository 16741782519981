import React, { useState, useEffect } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  Typography, 
  CircularProgress,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Snackbar,
  IconButton,
  InputAdornment,
  Box
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { fetchUserAffiliateStatus, becomeAffiliate, fetchAffiliateSales, updatePayoutWallet, deletePayoutWallet } from '../utils/Api';

const AffiliateModal = ({ open, onClose, token }) => {
  const [affiliateStatus, setAffiliateStatus] = useState(null);
  const [salesData, setSalesData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [payoutWallet, setPayoutWallet] = useState('');

  useEffect(() => {
    if (open) {
      fetchAffiliateData();
    }
  }, [open]);

  const fetchAffiliateData = async () => {
    setLoading(true);
    setError(null);
    try {
      const status = await fetchUserAffiliateStatus(token);
      setAffiliateStatus(status);
      setPayoutWallet(status.payoutWallet || '');
      if (status.status !== 'none') {
        const sales = await fetchAffiliateSales(token);
        setSalesData(sales);
      }
    } catch (err) {
      setError('Failed to fetch affiliate data');
    } finally {
      setLoading(false);
    }
  };

  const handleBecomeAffiliate = async (type) => {
    setLoading(true);
    setError(null);
    try {
      await becomeAffiliate(token, type);
      await fetchAffiliateData();
    } catch (err) {
      setError(`Failed to become a ${type}`);
    } finally {
      setLoading(false);
    }
  };

  const handleCopyReferralLink = () => {
    if (affiliateStatus.referralLink) {
      navigator.clipboard.writeText(affiliateStatus.referralLink)
        .then(() => {
          setSnackbarOpen(true);
        })
        .catch((err) => {
          console.error('Failed to copy: ', err);
        });
    }
  };

  const handleUpdatePayoutWallet = async () => {
    try {
      await updatePayoutWallet(token, payoutWallet);
      setSnackbarMessage('Payout wallet updated successfully');
      setSnackbarOpen(true);
      fetchAffiliateData();
    } catch (error) {
      setError('Failed to update payout wallet');
    }
  };

  const handleDeletePayoutWallet = async () => {
    try {
      await deletePayoutWallet(token);
      setSnackbarMessage('Payout wallet deleted successfully');
      setSnackbarOpen(true);
      setPayoutWallet('');
      fetchAffiliateData();
    } catch (error) {
      setError('Failed to delete payout wallet');
    }
  };

  const renderAffiliateInfo = () => (
    <>
      <Typography variant="h6">
        Current Status: {affiliateStatus.status}
      </Typography>
      {affiliateStatus.referralCode && (
        <>
          <TextField
            fullWidth
            label="Your Referral Link"
            value={affiliateStatus.referralLink}
            margin="normal"
            variant="outlined"
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="copy referral link"
                    onClick={handleCopyReferralLink}
                    edge="end"
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Typography variant="body1" mt={2}>
            Commission: {affiliateStatus.commissionPercentage}%
            {affiliateStatus.promoterCommission && ` (Your Promoter: ${affiliateStatus.promoterCommission}%)`}
          </Typography>
          <Box mt={2}>
            <TextField
              fullWidth
              label="Affiliate Payout Wallet (TRC20 USDT)"
              value={payoutWallet}
              onChange={(e) => setPayoutWallet(e.target.value)}
              margin="normal"
              variant="outlined"
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpdatePayoutWallet}
              sx={{ mt: 1, mr: 1 }}
            >
              Update Payout Wallet
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleDeletePayoutWallet}
              sx={{ mt: 1 }}
            >
              Delete Payout Wallet
            </Button>
          </Box>
        </>
      )}
    </>
  );

  const renderSalesTable = () => (
    <>
      <Typography variant="h6" mt={3}>Recent Sales</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell align="right">Commission</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {salesData.salesData.map((sale, index) => (
              <TableRow key={index}>
                <TableCell>{new Date(sale.date).toLocaleDateString()}</TableCell>
                <TableCell align="right">${sale.amount.toFixed(2)}</TableCell>
                <TableCell align="right">${sale.commission.toFixed(2)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant="h6" mt={2}>
        Total Commission Earned: ${salesData.totalCommission.toFixed(2)}
      </Typography>
    </>
  );

  const renderAvailableOptions = () => {
    if (affiliateStatus.availableOptions.length === 0) {
      return (
        <Typography variant="body1" mt={2}>
          You have no available options to become an affiliate.
        </Typography>
      );
    }

    return (
      <Box mt={2}>
        {affiliateStatus.availableOptions.includes('Promoter') && (
          <>
            <Typography variant="body1" mb={1}>
              Become a Promoter and earn 20% commission on referred users' monthly payments!
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleBecomeAffiliate('Promoter')}
              sx={{ mt: 1, mr: 1 }}
            >
              Become a Promoter
            </Button>
          </>
        )}
        {affiliateStatus.availableOptions.includes('Agent') && (
          <>
            <Typography variant="body1" mt={2} mb={1}>
              Become an Agent and earn 15% commission, while your Promoter earns 5%!
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleBecomeAffiliate('Agent')}
              sx={{ mt: 1, mr: 1 }}
            >
              Become an Agent
            </Button>
          </>
        )}
      </Box>
    );
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>Affiliate Program</DialogTitle>
        <DialogContent>
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
              <CircularProgress />
            </Box>
          ) : error ? (
            <Typography color="error">{error}</Typography>
          ) : (
            <>
              {renderAffiliateInfo()}
              {renderAvailableOptions()}
              {salesData && renderSalesTable()}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">Close</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </>
  );
};

export default AffiliateModal;