import React, { useState, useEffect } from 'react';
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField, 
  Checkbox, FormControlLabel, Grid, Card, CardContent, Typography, useMediaQuery, Box
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { getUsers, addUser, updateUser, deleteUser } from '../utils/Api';

const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
}));

const StyledForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(4),
  [theme.breakpoints.up('md')]: {
    maxWidth: '400px',
  },
}));

const UserManagement = ({ token }) => {
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({ username: '', password: '', deviceId: '', isAdmin: false });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const fetchedUsers = await getUsers(token);
      setUsers(fetchedUsers);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleAddUser = async (e) => {
    e.preventDefault();
    try {
      await addUser(token, newUser);
      setNewUser({ username: '', password: '', deviceId: '', isAdmin: false });
      fetchUsers();
    } catch (error) {
      console.error('Error adding user:', error);
    }
  };

  const handleUpdateUser = async (id, updatedData) => {
    try {
      await updateUser(token, id, updatedData);
      fetchUsers();
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  const handleDeleteUser = async (id) => {
    try {
      await deleteUser(token, id);
      fetchUsers();
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  const renderUserCard = (user) => (
    <StyledCard key={user._id}>
      <CardContent>
        <Typography variant="h6">{user.username}</Typography>
        <Typography variant="body2">Device ID: {user.deviceId}</Typography>
        <Typography variant="body2">Admin: {user.isAdmin ? 'Yes' : 'No'}</Typography>
        <Box mt={2}>
          <Button onClick={() => handleUpdateUser(user._id, { isAdmin: !user.isAdmin })} variant="outlined" size="small" sx={{ mr: 1 }}>
            Toggle Admin
          </Button>
          <Button onClick={() => handleDeleteUser(user._id)} variant="outlined" color="secondary" size="small">
            Delete
          </Button>
        </Box>
      </CardContent>
    </StyledCard>
  );

  const renderUserTable = () => (
    <TableContainer component={Paper} sx={{ maxHeight: 'calc(100vh - 300px)', overflowY: 'auto' }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Username</TableCell>
            <TableCell>Device ID</TableCell>
            <TableCell>Is Admin</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <TableRow key={user._id}>
              <TableCell>{user.username}</TableCell>
              <TableCell>{user.deviceId}</TableCell>
              <TableCell>{user.isAdmin ? 'Yes' : 'No'}</TableCell>
              <TableCell>
                <Button onClick={() => handleUpdateUser(user._id, { isAdmin: !user.isAdmin })} variant="outlined" size="small" sx={{ mr: 1 }}>
                  Toggle Admin
                </Button>
                <Button onClick={() => handleDeleteUser(user._id)} variant="outlined" color="secondary" size="small">
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Box className="user-management">
      <Typography variant="h4" gutterBottom>User Management</Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <StyledForm onSubmit={handleAddUser}>
            <TextField
              label="Username"
              value={newUser.username}
              onChange={(e) => setNewUser({ ...newUser, username: e.target.value })}
              fullWidth
            />
            <TextField
              type="password"
              label="Password"
              value={newUser.password}
              onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
              fullWidth
            />
            <TextField
              label="Device ID"
              value={newUser.deviceId}
              onChange={(e) => setNewUser({ ...newUser, deviceId: e.target.value })}
              fullWidth
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={newUser.isAdmin}
                  onChange={(e) => setNewUser({ ...newUser, isAdmin: e.target.checked })}
                />
              }
              label="Is Admin"
            />
            <Button type="submit" variant="contained" color="primary">Add User</Button>
          </StyledForm>
        </Grid>
        <Grid item xs={12} md={8}>
          {isMobile || isTablet ? (
            <Grid container spacing={2}>
              {users.map(renderUserCard)}
            </Grid>
          ) : (
            renderUserTable()
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserManagement;